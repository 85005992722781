export const supportedCities = [
    'Atlanta, GA',
    'Austin, TX',
    'Baltimore, MD',
    'Boston, MA--NH--RI',
    'Chicago, IL--IN',
    'Charlotte, NC--SC',
    'Cincinnati, OH--KY--IN',
    'Cleveland, OH',
    'Dallas--Fort Worth--Arlington, TX',
    'Denver--Aurora, CO',
    'Detroit, MI',
    'Houston, TX',
    'Las Vegas--Henderson, NV',
    'Los Angeles--Long Beach--Anaheim, CA',
    'Miami, FL',
    'Minneapolis--St. Paul, MN--WI',
    'New York--Newark, NY--NJ--CT',
    'Orlando, FL',
    'Pittsburgh, PA',
    'Philadelphia, PA--NJ--DE--MD',
    'Phoenix--Mesa, AZ',
    'Portland, OR--WA',
    'Riverside--San Bernardino, CA',
    'Sacramento, CA',
    'San Antonio, TX',
    'San Diego, CA',
    'San Francisco--Oakland, CA',
    'Seattle, WA',
    'St. Louis, MO--IL',
    'Tampa--St. Petersburg, FL',
    'Washington, DC--VA--MD'
]

export const supportedCityCordinates = {
    'Atlanta, GA': { lng: -84.32691971071415, lat: 33.759365066102475 },
    'Austin, TX': { lng: -97.715942, lat: 30.269501 },
    'Baltimore, MD': { lng: -76.598633, lat: 39.264969 },
    'Boston, MA--NH--RI': { lng: -71.06392525738285, lat: 42.353758547637085 },
    'Chicago, IL--IN': { lng: -87.83848727610835, lat: 41.839959931547156 },
    'Charlotte, NC--SC': { lng: -80.843124, lat: 35.227085 },
    'Cincinnati, OH--KY--IN': { lng: -84.47402669359252, lat: 39.11914999463926 },
    'Cleveland, OH': { lng: -81.61977266135793, lat: 41.41286754953836 },
    'Dallas--Fort Worth--Arlington, TX': { lng: -96.94387798448972, lat: 32.78937749956576 },
    'Denver--Aurora, CO': { lng: -104.991531, lat: 39.742043 },
    'Detroit, MI': { lng: -83.045753, lat: 42.331429 },
    'Houston, TX': { lng: -95.39933430454754, lat: 29.739488624385984 },
    'Las Vegas--Henderson, NV': { lng: -115.176468, lat: 36.188110 },
    'Los Angeles--Long Beach--Anaheim, CA': { lng: -118.243683, lat: 34.052235 },
    'Miami, FL': { lng: -80.191788, lat: 25.761681 },
    'Minneapolis--St. Paul, MN--WI': { lng: -93.29106904224143, lat: 44.97973338940676 },
    'New York--Newark, NY--NJ--CT': { lng: -73.935242, lat: 40.730610 },
    'Orlando, FL': { lng: -81.379234, lat: 28.538336 },
    'Pittsburgh, PA': { lng: -79.995888, lat: 40.440624 },
    'Philadelphia, PA--NJ--DE--MD': { lng: -75.14812601687287, lat: 39.95366720828446 },
    'Phoenix--Mesa, AZ': { lng: -112.074036, lat: 33.448376 },
    'Portland, OR--WA': { lng: -122.66092252301331, lat: 45.52153475485946 },
    'Riverside--San Bernardino, CA': { lng: -117.396156, lat: 33.953350 },
    'Sacramento, CA': { lng: -121.478851, lat: 38.575764 },
    'San Antonio, TX': { lng: -98.491142, lat: 29.424349 },
    'San Diego, CA': { lng: -117.161087, lat: 32.715736 },
    'San Francisco--Oakland, CA': { lng: -122.446747, lat: 37.733795 },
    'Seattle, WA': { lng: -122.335167, lat: 47.608013 },
    'St. Louis, MO--IL': { lng: -90.29577958396759, lat: 38.632986685626975 },
    'Tampa--St. Petersburg, FL': { lng: -82.452606, lat: 27.964157 },
    'Washington, DC--VA--MD': { lng: -77.009056, lat: 38.889805 }
}

export const supportedYears = ['1980', '1990', '2000', '2010', '2018'];